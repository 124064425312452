/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";
import "@js/parts/unpoly";
import { slider, heroSlider, miniSlider } from "@js/parts/alpine/slider.js";
import menu from "@js/parts/alpine/menu";

// Only load photoswipe if needed
if(document.getElementById('hero-gallery')) {
  import("@js/parts/photoswipe");
}

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
window.Alpine = Alpine;

Alpine.plugin(focus);
Alpine.data('heroSlider', heroSlider);
Alpine.data('slider', slider);
Alpine.data('miniSlider', miniSlider);
Alpine.data('menu', menu);
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

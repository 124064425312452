export default function() {
  return {

    init() {
      this.setTabIndexes(-1);
      this.$watch('menuOpen', open => this.setTabIndexes(open ? null : -1));
    },

    setTabIndexes(val) {
      let els = this.$el.querySelectorAll('button, a');
      Array.from(els).forEach(el => el.tabIndex = val);
    }

  };
}

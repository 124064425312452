import Swiper from 'swiper';
import {Navigation, Pagination, Autoplay, EffectFade} from 'swiper/modules';

/**
 * Hero Slideshow component.
 *
 * @param opts
 * @returns {{init(): void, swiper: null}}
 */
export function heroSlider(opts = {}) {

  let swiper = null;
  let swiperEl = null;
  let swiperNav = null;
  let autoTransitionSpeed = 1000;
  let navTransitionSpeed = 300;

  return {

    pauseTimer: null,
    resumeTimer: null,

    init() {
      swiperEl = this.$refs.swiperEl || this.$root;

      swiper = new Swiper(swiperEl, {
        speed: autoTransitionSpeed,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        effect: 'fade',
        loop: true,
        slidesPerView: 1,
        modules: [Autoplay, EffectFade]
      });

      window.addEventListener('hero-nav-active', () => {
        clearTimeout(this.resumeTimer);
        this.pauseTimer = setTimeout(() => {
          swiper.autoplay.stop();
        }, 100);
      });

      window.addEventListener('hero-nav-inactive', () => {
        clearTimeout(this.pauseTimer);
        this.resumeTimer = setTimeout(() => {
          swiper.autoplay.start();
        }, 2000);
      });
    },

    selectSlide(idx = null) {
      if(idx == null) return;
      swiper.params.speed = 300;
      swiper.slideTo(idx);
    }
  };
}

/**
 * Slider component.
 *
 * @param opts
 * @returns {{init(): void, swiper: null}}
 */
export function slider(opts = {}) {
  let swiper = null;
  let swiperEl = null;

  return {
    init() {
      swiperEl = this.$refs.swiperEl || this.$root;
      swiper = new Swiper(swiperEl, {
        loop: true,
        slidesPerView: 1,
        speed: 500,
        effect: 'fade',
        navigation: {
          nextEl: ".slider-button-next",
          prevEl: ".slider-button-prev"
        },
        modules: [Navigation, EffectFade],
      });
    },
  };
}

/**
 * Slider component.
 *
 * @param opts
 * @returns {{init(): void, swiper: null}}
 */
export function miniSlider(opts = {}) {
  let swiper = null;
  let swiperEl = null;

  return {

    pauseTimer: null,
    startTimer: null,

    init() {
      swiperEl = this.$refs.swiperEl || this.$root;
      swiper = new Swiper(swiperEl, {
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 800,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        speed: 500,
        effect: 'fade',
        modules: [Autoplay, EffectFade],
      });

      swiper.autoplay.stop();

      this.$root.addEventListener('mouseenter', () => {
        swiper.slideToLoop(1);
        clearTimeout(this.pauseTimer);
        this.startTimer = setTimeout(() => {
          swiper.autoplay.start();
        }, 300); // Gets around annoying issue of first delay after skipping to 1st slide being shorter
      });
      this.$root.addEventListener('mouseleave', () => {
        clearTimeout(this.startTimer);
        this.pauseTimer = setTimeout(() => {
          swiper.autoplay.stop();
          swiper.slideToLoop(0);
        }, 10);
      });
    },
  };
}
